import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CONTENT_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
};

const TEXT_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
};

const Heading = ({
  children,
  left,
  right,
  className,
  contentAlign,
  textAlign,
}) => {
  const getAdClassList = () => {
    let adClassList = [];

    switch (contentAlign) {
      case CONTENT_ALIGN.CENTER:
        adClassList.push('heading-center');
        break;
      case CONTENT_ALIGN.LEFT:
      default:
        if (left && right) adClassList.push('heading-flex');
    }

    switch (textAlign) {
      case TEXT_ALIGN.CENTER:
        adClassList.push('text-center');
        break;
      case TEXT_ALIGN.LEFT:
      default:
    }

    return adClassList;
  };

  return (
    <div
      className={classNames('heading', className, getAdClassList())}
    >
      {left && <div className="heading-left">{left}</div>}
      {right && <div className="heading-right">{right}</div>}
      {children}
    </div>
  );
};

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  contentAlign: PropTypes.oneOf(Object.values(CONTENT_ALIGN)),
  textAlign: PropTypes.oneOf(Object.values(TEXT_ALIGN)),
};

Heading.defaultProps = {
  children: null,
  left: null,
  right: null,
  contentAlign: CONTENT_ALIGN.LEFT,
  textAlign: TEXT_ALIGN.LEFT,
};

export default Heading;
