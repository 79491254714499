import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ImageBackground,
  ImageBackgroundProps,
} from '../ImageBackground';
import style from './style.module.scss';

export enum BANNER_SIZE {
  SMALL = 'small',
  BIG = 'big',
}
export type BannerSizeString = `${BANNER_SIZE}`;

export enum BANNER_BORDER {
  NONE = 'none',
  ALWAYS = 'always',
  HOVER = 'hover',
}
export type BannerBorderString = `${BANNER_BORDER}`;

export type BannerBackgroundImageProps = Partial<ImageBackgroundProps> &
  Pick<ImageBackgroundProps, 'src'>;

type Props = {
  bgImageProps: BannerBackgroundImageProps;
  className?: string;
  size?: BANNER_SIZE | BannerSizeString;
  children: JSX.Element;
  border?: BANNER_BORDER | BannerBorderString;
};

const Banner = ({
  bgImageProps,
  className,
  size,
  children,
  border,
}: Props) => {
  return (
    <div
      className={classNames(className, {
        [style['banner-big']]: size === BANNER_SIZE.BIG,
        [style['banner-border']]: border === BANNER_BORDER.ALWAYS,
        [style['banner-border-hover']]:
          border === BANNER_BORDER.HOVER,
      })}
    >
      <ImageBackground {...bgImageProps}>{children}</ImageBackground>
    </div>
  );
};

Banner.propTypes = {
  bgImageProps: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.oneOf(Object.values(BANNER_SIZE)),
  border: PropTypes.oneOf(Object.values(BANNER_BORDER)),
  linkTo: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Banner.defaultProps = {
  size: BANNER_SIZE.SMALL,
  border: BANNER_BORDER.NONE,
  linkTo: '#',
};

export default Banner;
