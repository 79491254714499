import { Heading, Image, Link, Title } from 'components/common';
import React from 'react';
import featuredBysData from 'lib/misc/featuredBy/data/raw.json';
import { m } from 'framer-motion';

const { featured_bys } = featuredBysData;

const FEATURED_BY_IMAGE_HEIGHT = 70;
const FEATURED_BY_IMAGE_WIDTH = 150;

export const FeaturedBy = () => {
  return (
    <m.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 1, delay: 0.1 }}
      viewport={{ once: true }}
    >
      <Heading className="tw-mb-20" textAlign="center">
        <Title className="tw-font-extrabold">
          As Raved & Featured On:
        </Title>
        <p>Thanks for making us look so legit!</p>
      </Heading>
      <div className="brands-display">
        <div className="tw-flex tw-flex-wrap tw-justify-center">
          {featured_bys.map(({ name, image, action_link }, index) => (
            <m.div
              className="tw-basis-1/2 sm:tw-basis-1/3 md:tw-basis-1/4"
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.1 * (index + 1 / 2),
                },
                type: 'spring',
              }}
              viewport={{ once: true }}
            >
              <Link
                to={action_link ? action_link : '#'}
                target={action_link ? '_target' : '_self'}
                className="brand"
              >
                <Image
                  alt={name}
                  src={image}
                  width={FEATURED_BY_IMAGE_WIDTH}
                  height={FEATURED_BY_IMAGE_HEIGHT}
                  objectFit="scale-down"
                />
              </Link>
            </m.div>
          ))}
        </div>
      </div>
    </m.div>
  );
};
