import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import style from './style.module.scss';

type Props = {
  className?: string;
  children: JSX.Element;
};

const BannerTitle = ({ className, children }: Props) => {
  return (
    <h3 className={classNames(className, style['banner-title'])}>
      {children}
    </h3>
  );
};

BannerTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BannerTitle;
