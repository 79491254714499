import { ComponentPropsWithoutRef } from 'react';

type DivProps = ComponentPropsWithoutRef<'div'>;

type Props = {
  [Property in keyof DivProps]?: DivProps[Property];
} & {
  children: React.ReactNode;
};

const Container = ({
  children,
  className = '',
  ...others
}: Props) => {
  return (
    <div
      className={`tw-container tw-mx-auto tw-p-4 ${className}`}
      {...others}
    >
      {children}
    </div>
  );
};

export default Container;
