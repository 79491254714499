import React, { CSSProperties } from 'react';
import { Image, ImageProps } from 'components/common/Image';
import styles from './ImageBackground.module.scss';

export type ImageBackgroundProps = {
  [Property in keyof ImageProps]?: ImageProps[Property];
} & {
  src: string;
  children?: JSX.Element;
  containerStyle?: CSSProperties;
  childClassname?: JSX.ElementClass;
  style?: CSSProperties;
  position?: 'left' | 'right';
  [others: string]: any;
};

const ImageBackground = ({
  src,
  alt,
  width,
  height,
  sizes,
  priority,
  unoptimized,
  objectFit,
  objectPosition,
  quality,
  loading,
  children,
  containerStyle,
  childClassname,
  style,
  position,
  ...others
}: ImageBackgroundProps) => {
  return (
    <div
      className={styles.container}
      style={{ width, height, ...containerStyle }}
    >
      <div className={styles.bgWrap} style={style} {...others}>
        <Image
          src={src}
          alt={alt}
          layout="fill"
          sizes={sizes}
          priority={priority}
          unoptimized={unoptimized}
          objectFit={objectFit}
          objectPosition={objectPosition}
          quality={quality}
          loading={loading}
          {...others}
        />
      </div>
      <div className={`${styles.children} ${childClassname}`}>
        {children}
      </div>
    </div>
  );
};

ImageBackground.defaultProps = {
  alt: undefined,
  sizes: undefined,
  quality: 75,
  priority: false,
  objectFit: 'cover',
  objectPosition: undefined,
  unoptimized: false,
};

export { ImageBackground };
