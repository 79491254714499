import React from 'react';
import classNames from 'classnames';
import Balancer from 'react-wrap-balancer';

interface TextBalancerProps {
  children: React.ReactNode;
  ratio?: number;
  preferNative?: boolean;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

const TextBalancer = ({
  children,
  className,
  as: Element = 'p',
  ...props
}: TextBalancerProps) => {
  return (
    <Element className={classNames(className)}>
      <Balancer {...props}>{children}</Balancer>
    </Element>
  );
};

export default TextBalancer;
