import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';

export enum TITLE_ALIGN {
  LEFT = 'left',
  CENTER = 'center',
}
export type TitleAlignString = `${TITLE_ALIGN}`;

export enum TITLE_SIZE {
  SMALL = 'small',
  DEFAULT = 'default',
}
export type TitleSizeString = `${TITLE_SIZE}`;

interface Props {
  align?: TITLE_ALIGN | TitleAlignString;
  size?: TITLE_SIZE | TitleSizeString;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const Title = ({
  align = TITLE_ALIGN.LEFT,
  size = TITLE_SIZE.DEFAULT,
  children,
  className,
  style,
}: Props) => {
  return (
    <h2
      className={classNames([
        {
          title: size === TITLE_SIZE.DEFAULT,
          'text-center': align === TITLE_ALIGN.CENTER,
          'title-sm': size === TITLE_SIZE.SMALL,
        },
        ...(className ? [className] : []),
      ])}
      style={style}
    >
      {children}
    </h2>
  );
};
