import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Subtitle = ({ children, className, style }) => {
  return (
    <p className={classNames('title-desc', className)} style={style}>
      {children}
    </p>
  );
};

Subtitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Subtitle.defaultProps = {
  className: null,
  style: {},
};

export default Subtitle;
