import React from 'react';
import PropTypes from 'prop-types';

import { safeContent } from 'utils';

import { Image } from '../Image';

const Testimonial = ({ image, content, name, job, color, lead }) => {
  return (
    <blockquote
      className={`testimonial ${
        image ? '' : 'testimonial-icon'
      } text-center ${'white' === color ? 'text-white' : ''}`}
    >
      {image ? (
        <Image src={image} width={70} height={70} alt="user" />
      ) : (
        ''
      )}
      {lead ? <p className="lead">{lead}</p> : ''}
      <p dangerouslySetInnerHTML={safeContent(content)}></p>
      <cite>
        {name}
        <span>{job}</span>
      </cite>
    </blockquote>
  );
};

Testimonial.propTypes = {
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  image: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.oneOf(['white']),
  lead: PropTypes.string,
};

export default React.memo(Testimonial);
