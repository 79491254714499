import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';

import stylesheet from './style.module.scss';
import classNames from 'classnames';

type DivElementStyle = NonNullable<
  JSX.IntrinsicElements['div']['style']
>;

type Props = {
  className?: string;
  style?: CSSProperties;
  children: JSX.Element;
  contentAlign?: DivElementStyle['alignContent'];
  textAlign?: DivElementStyle['textAlign'];
};

const BannerContent = ({
  className,
  style,
  children,
  contentAlign,
  textAlign,
}: Props) => {
  return (
    <div
      className={classNames(stylesheet['banner-content-container'], {
        [stylesheet['align-center']]: contentAlign === 'center',
        'text-center': textAlign === 'center',
      })}
    >
      <div
        className={classNames(
          className,
          stylesheet['banner-content'],
        )}
        style={style}
      >
        {children}
      </div>
    </div>
  );
};

BannerContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BannerContent;
